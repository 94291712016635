<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技在第五届全球金融科技创业大赛中荣获“最具创新力企业”奖
              </p>
              <div class="fst-italic mb-2">2023年5月17日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >大赛获奖</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/38/01-会场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    5月12日，第五届全球金融科技创业大赛总决赛及颁奖典礼在北京拉开帷幕，从各赛区脱颖而出的50家企业在总决赛上各放异彩。非凸科技作为50强企业受邀参加总决赛，荣获“最具创新力企业”奖项，在商业模式成熟度、市场地位、科技创新、产品性能等多方面获得认可。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/38/02-奖项.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    决赛上，非凸科技联合创始人&CEO王浚澎从公司团队、产品创新性、核心竞争力、商业模式、发展前景等角度进行了分享，并与评审嘉宾进行了互动交流，获得一致好评与肯定。非凸科技最终凭借优异的产品性能表现、领先的技术创新以及突出的券商合作案例突围，将“最具创新力企业”奖项收入囊中。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/38/03-王浚澎.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    此次获奖，不仅彰显了非凸科技在金融科技领域的持续深耕与落地实力优势，更凸显了非凸AI算法及交易工具在垂直应用场景的技术领先地位。与此同时，也充分证明了非凸科技受到了行业与市场的积极认可与肯定，也将成为非凸科技接下来继续扎根成长的动力。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/38/04-领奖.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    作为前沿科技创新企业，非凸科技基于对技术发展趋势、用户需求和业务场景的深刻理解，搭建了功能丰富、架构先进、高性能、高可用的非凸算法生态，包括算法总线、核心算法、交易客户端、绩效分析系统、回测系统、仿真系统、实时风险管理、运维监控系统等，满足客户选择一站式算法业务解决方案。
                  </p>
                  <p>
                    未来，非凸科技将秉承行业初心，紧跟行业趋势，以最具实力的技术创新及产品研发，持续推动数智化交易。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News38",
};
</script>

<style></style>
